/* eslint-disable no-console,no-undef */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { StyledModal } from "../PeopleHomeV2/ManageBuddies/styles";
import { CalendarHeader } from "../ChallengeDetailsV2/InviteAmigoPopUp/styles";
import { BirthdayWrapper, WishCard, WishButton, PreviousButton, NewActivityDropdownCommon, UserInitialsMain, NoData } from "./styles";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { getAmigoBirthday, getWorkAnniversary, fetchAnniversaryBirthdayUsers, getCompanyLocation, getCompanyDepartment } from "../../redux/actions";
import { ImageUrl } from "../../utils/constants";
import { convertMonthFromDate, findMonth, fetchApi } from '../../utils/methods';
import moment from 'moment';
import {months} from '../../../mockData';
import { TitleContainer } from './styles';
import {StyledHeader, HeaderText, CloseIcon} from '../PhotoAndPostFlow/styles';
import { feedCloseIcon } from '../../utils/icons';
import { Dropdown } from 'react-bootstrap';
import { toast } from "react-toastify";
import {GetUserCelebrationData} from '../../redux/constants/apiConstants';

class BirthdayWishPopup extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      currentMonth: findMonth(date.getMonth()).toLowerCase(),
      activityArrow: false,
      currentMonthCountStore:date.getMonth()+1,
      selectedLocationId: 0,
      selectedLocationValue: "All",
      selectedDepartmentId: 0,
      selectedDepartmentValue: "All",
      anniversaryBirthdayUsersData: [],
      popupType: props?.popupType?props?.popupType:2,
    }
  }

  componentDidMount() {
    const { getAmigoBirthday, getWorkAnniversary, fetchAnniversaryBirthdayUsers, getCompanyLocation, getCompanyDepartment, user } = this.props;
    const {popupType, selectedLocationId, selectedDepartmentId, currentMonthCountStore} = this.state;
    getAmigoBirthday();
    getWorkAnniversary();
    fetchAnniversaryBirthdayUsers();
    getCompanyLocation(user?.company_id,1);
    getCompanyDepartment(user?.company_id,1);
    this.getBirthdayAndAnniversaryData(popupType, currentMonthCountStore, selectedLocationId, selectedDepartmentId)
  }

  getBirthdayAndAnniversaryData = async(type, month, location, department)=>{
    let apiUrl = GetUserCelebrationData + "/" + type + "/" + month + "/" + location +"/"+department;
    try {
      const res = await fetchApi(apiUrl , "GET");
      if (res.status === "success") {
        this.setState({anniversaryBirthdayUsersData: res?.data})
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      window.console.log(error);
    }
  }

  handleRequest = (data, filter) => {
    const { peerCallBack, hidePopup } = this.props;
    hidePopup();
    peerCallBack(data, 0, filter);
  };

  handleRequestAnniversary = (data,filter) => {
    const { peerCallBack, hidePopup } = this.props;
    hidePopup();
    peerCallBack(data, 1, filter);
  };

  onSelect = (name, value, id) => {
    const {popupType, selectedLocationId, selectedDepartmentId} = this.state;
    this.setState({
      [name]: value,
      currentMonthCountStore: id
    }, () => {
      this.getBirthdayAndAnniversaryData(popupType, id, selectedLocationId, selectedDepartmentId)
    })
  };

  onSelectValue = (idState, valueState, id, value) => {
    const {popupType, selectedDepartmentId, currentMonthCountStore} = this.state;
    this.setState({
      [idState]: id?id:0,
      [valueState]: value
    }, () => {
      this.getBirthdayAndAnniversaryData(popupType, currentMonthCountStore, id?id:0, selectedDepartmentId)
    })
  };

  onSelectValueDepartment = (idState, valueState, id, value) => {
    const {popupType, selectedLocationId, currentMonthCountStore} = this.state;
    this.setState({
      [idState]: id?id:0,
      [valueState]: value
    }, () => {
      this.getBirthdayAndAnniversaryData(popupType, currentMonthCountStore, selectedLocationId, id?id:0)
    })
  };

  containsDefaultPng = (str) => {
    const defaultPngFound = str?.includes("default.png");
    return defaultPngFound;
  }
  
  getInitials = (inputString) =>{
    const words = inputString.split(' ');
    const initials = words.map((word) => word[0].toUpperCase());
    return initials.join('');
  }

  render() {
    const { show, birthdayList, hidePopup, showBirthday, anniversaryList, t, locationDetails, departmentDetails, user} =this.props;
    const {currentMonth,  selectedLocationId, selectedLocationValue, selectedDepartmentId, selectedDepartmentValue, anniversaryBirthdayUsersData, currentMonthCountStore} =this.state;
    const date = new Date();
    const month = findMonth(date.getMonth()).toLowerCase();
    return  (showBirthday?
      <StyledModal show={show} width={"800px"} height="auto">
        <StyledHeader background border>
          {<HeaderText padding="0 0 0 25px" color>{(t("Birthday’s"))}</HeaderText>}
          <CloseIcon onClick={() => hidePopup()}>
            {feedCloseIcon()}
          </CloseIcon>
        </StyledHeader>
        <CalendarHeader height="100%" justify>
          <div className="dropdown-wrapper">
            <div className="label">Month</div>
            <div className="filter-dropdown">
              <TitleContainer paddingV="0px !important">
                <NewActivityDropdownCommon
                  padding={"10px 8px"}
                  height="42px"
                  onClick={() => this.setState({ activityArrow: !this.state.activityArrow })}
                >
                  <Dropdown.Toggle>
                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center", color:currentMonth? "#005C87":"#005C874D",fontFamily:"Rubik-Medium", width:"100%"}}>
                      <div style={{color: "#005C87",textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{currentMonth?currentMonth:"Select Option"}</div>
                      {<div>        
                        <img
                          alt="image"
                          src={ImageUrl + "/social-feed/dropdown-arrow.svg"}
                        />
                      </div>}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {months && months.length > 0 && months.map((list) => (
                      <Dropdown.Item 
                        key={list.id}
                        onClick={() => this.onSelect('currentMonth', list.key, list.id)}
                        active={currentMonth == list.name}
                      >
                        {this.props.t(list.name)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </NewActivityDropdownCommon>
              </TitleContainer>
            </div>
          </div>
          {locationDetails?.length>0&&<div className="dropdown-wrapper">
            <div className="label">Location</div>
            <div className="filter-dropdown">
              <TitleContainer paddingV="0px !important">
                <NewActivityDropdownCommon
                  padding={"10px 8px"}
                  widthdropdown
                  height="42px"
                  margin="0px"
                  onClick={() => this.setState({ activityArrow: !this.state.activityArrow })}
                >
                  <Dropdown.Toggle>
                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center", color:selectedLocationValue? "#005C87":"#005C874D",fontFamily:"Rubik-Medium", width:"100%"}}>
                      <div style={{color: "#005C87",textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{selectedLocationValue?selectedLocationValue:"Select Option"}</div>
                      {<div>        
                        <img
                          alt="image"
                          src={ImageUrl + "/social-feed/dropdown-arrow.svg"}
                        />
                      </div>}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {locationDetails && locationDetails?.map((list) => (
                      <Dropdown.Item 
                        key={list.id}
                        onClick={() => this.onSelectValue('selectedLocationId','selectedLocationValue', list.id, list.location)}
                        active={selectedLocationId == list.id}
                      >
                        {this.props.t(list.location)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </NewActivityDropdownCommon>
              </TitleContainer>
            </div>
          </div>}
          {departmentDetails?.length>0&&<div className="dropdown-wrapper">
            <div className="label">Department</div>
            <div className="filter-dropdown">
              <TitleContainer paddingV="0px !important">
                <NewActivityDropdownCommon
                  padding={"10px 8px"}
                  widthdropdown
                  margin="0px"
                  height="42px"
                  marginleft="-50px"
                  onClick={() => this.setState({ activityArrow: !this.state.activityArrow })}
                >
                  <Dropdown.Toggle>
                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center", color:selectedDepartmentValue? "#005C87":"#005C874D",fontFamily:"Rubik-Medium", width:"100%"}}>
                      <div style={{color: "#005C87",textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{selectedDepartmentValue?selectedDepartmentValue:"Select Option"}</div>
                      {<div>        
                        <img
                          alt="image"
                          src={ImageUrl + "/social-feed/dropdown-arrow.svg"}
                        />
                      </div>}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {departmentDetails && departmentDetails?.map((list) => (
                      <Dropdown.Item 
                        key={list.id}
                        onClick={() => this.onSelectValueDepartment('selectedDepartmentId','selectedDepartmentValue', list.id, list.department)}
                        active={selectedDepartmentId == list.id}
                      >
                        {this.props.t(list.department)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </NewActivityDropdownCommon>
              </TitleContainer>
            </div>
          </div>}
        </CalendarHeader>
        {currentMonth==month && selectedLocationId==0 && selectedDepartmentId==0?<BirthdayWrapper flex={(birthdayList.upcoming_birthday===0&&birthdayList.today_birthday===0)||(birthdayList.upcoming_birthday===0&&birthdayList.recent_birthday===0)||(birthdayList.today_birthday===0&&birthdayList.recent_birthday===0)}>
          {birthdayList && birthdayList.today_birthday && birthdayList.today_birthday.length?<div className="title">{this.props.t("Today")}</div>:null}
          {birthdayList?.today_birthday?.length>0&&<div className="birthdayWrapper">
            {birthdayList && birthdayList.today_birthday && birthdayList.today_birthday.length ?
              birthdayList.today_birthday.map((data, index) => (
                <WishCard key={index}>
                  <div className="wrapper">
                    <div className="profile">
                      {!this.containsDefaultPng(data.profile_image)?
                        <img src={ImageUrl + "/" + data.profile_image} />:
                        <UserInitialsMain font background="#9C9C9C26">{`${this.getInitials(data?.name)}`}</UserInitialsMain>}
                    </div>
                    <div className="details">
                      <div className="name">{data.name}</div>
                      <div className="location">{data?.location?data?.location:null} {data?.department&&data?.location?` | ${data?.department}` :  data?.department?data?.department:null}</div>
                      <div className="date">{convertMonthFromDate(moment(data.birthday).format('Do MMMM'), this.props)}</div>
                    </div>
                  </div>
                  {user?.uid!=data?.uid?<WishButton onClick={() => this.handleRequest(data)}>
                    Share a Post
                  </WishButton>:
                    <WishButton disable>
                      Share a Post
                    </WishButton>
                  }
                </WishCard>
              )):
              null
            }
          </div>}
          {birthdayList && birthdayList.upcoming_birthday && birthdayList.upcoming_birthday.length?<div className="title">Upcoming</div>:null}
          {birthdayList?.upcoming_birthday?.length>0&&<div className="birthdayWrapper">
            {birthdayList && birthdayList.upcoming_birthday && birthdayList.upcoming_birthday.length?
              birthdayList.upcoming_birthday.map((data, index) => (
                <WishCard key={index}>
                  <div className="wrapper">
                    <div className="profile">
                      {!this.containsDefaultPng(data.profile_image)?
                        <img src={ImageUrl + "/" + data.profile_image} />:
                        <UserInitialsMain font background="#9C9C9C26">{`${this.getInitials(data?.name)}`}</UserInitialsMain>}
                    </div>
                    <div className="details">
                      <div className="name">{data.name}</div>
                      <div className="location">{data?.location?data?.location:null} {data?.department&&data?.location?` | ${data?.department}` :  data?.department?data?.department:null}</div>
                      <div className="date">{convertMonthFromDate(moment(data.birthday).format('Do MMMM'), this.props)}</div>
                    </div>
                  </div>
                </WishCard>
              )):
              null
            }
          </div>}
          {birthdayList && birthdayList.recent_birthday && birthdayList.recent_birthday.length?<div className="title">{this.props.t("Previously")}</div>:null}
          {birthdayList?.recent_birthday?.length>0&&<div className="birthdayWrapper">
            {birthdayList && birthdayList.recent_birthday && birthdayList.recent_birthday.length?
              birthdayList.recent_birthday && birthdayList.recent_birthday.map((data, index) => (
                <WishCard key={index}>
                  <div className="wrapper">
                    <div className="profile">
                      {!this.containsDefaultPng(data.profile_image)?
                        <img src={ImageUrl + "/" + data.profile_image} />:
                        <UserInitialsMain font background="#9C9C9C26">{`${this.getInitials(data?.name)}`}</UserInitialsMain>}
                    </div>
                    <div className="details">
                      <div className="name">{data.name}</div>
                      <div className="location">{data?.location?data?.location:null} {data?.department&&data?.location?` | ${data?.department}` :  data?.department?data?.department:null}</div>
                      <div className="date">{convertMonthFromDate(moment(data.birthday).format('Do MMMM'), this.props)}</div>
                    </div>
                  </div>
                  {user?.uid!=data?.uid?<PreviousButton onClick={() => this.handleRequest(data, 1)}>
                    Share a Post
                  </PreviousButton>:<PreviousButton disable>Share a Post</PreviousButton>}
                </WishCard>
              )):
              null
            }
          </div>}
        </BirthdayWrapper>:
          <BirthdayWrapper style={{display:"block"}}>
            {<div className="title">{currentMonthCountStore==date.getMonth()+1?"This month": currentMonthCountStore<date.getMonth()+1?this.props.t("Previously"): this.props.t("Upcoming")}</div>}
            <div className="birthdayWrapper">
              {anniversaryBirthdayUsersData && anniversaryBirthdayUsersData.birthday && anniversaryBirthdayUsersData?.birthday?.length>0 ?
                anniversaryBirthdayUsersData?.birthday?.map((data, index) => {
                  const birthdayDate = moment(data.birthday); // Parse the birthday date
                  const currentDate = moment(); // Get the current date
              
                  // Extract month and day for comparison
                  const birthdayMonth = birthdayDate.month(); // 0-11 (January is 0)
                  const birthdayDay = birthdayDate.date(); // 1-31
                  const currentMonth = currentDate.month(); // 0-11
                  const currentDay = currentDate.date(); // 1-31
              
                  // Check if birthday month is the same as current month and birthday day is less than or equal to current day
                  const isBirthdayVisible = (birthdayMonth === currentMonth && birthdayDay <= currentDay);
                  return(
                    <WishCard key={index}>
                      <div className="wrapper">
                        <div className="profile">
                          {!this.containsDefaultPng(data.profile_image)?
                            <img src={ImageUrl + "/" + data.profile_image} />:
                            <UserInitialsMain font background="#9C9C9C26">{`${this.getInitials(data?.full_name)}`}</UserInitialsMain>}
                        </div>
                        <div className="details">
                          <div className="name">{data.full_name}</div>
                          <div className="location">{data?.location?data?.location:null} {data?.department&&data?.location?` | ${data?.department}` :  data?.department?data?.department:null}</div>
                          <div className="date">{convertMonthFromDate(moment(data.birthday).format('Do MMMM'), this.props)}</div>
                        </div>
                        {isBirthdayVisible && user?.uid!=data?.uid?
                          <WishButton onClick={() => this.handleRequest(data, 1)}>
                          Share a post
                          </WishButton>:<WishButton disable>Share a post</WishButton>}
                      </div>
                    </WishCard>)
                }):<NoData>No birthdays found in the selected filter</NoData>}
            </div>       
          </BirthdayWrapper>
        }
      </StyledModal>: 
      <StyledModal show={show} width={"800px"} height="auto">
        <StyledHeader background border>
          {<HeaderText padding="0 0 0 25px" color>{('Work Anniversaries')}</HeaderText>}
          <CloseIcon onClick={() => hidePopup()}>
            {feedCloseIcon()}
          </CloseIcon>
        </StyledHeader>
        <CalendarHeader height="100%" justify>
          <div className="dropdown-wrapper">
            <div className="label">Month</div>
            <div className="filter-dropdown">
              <TitleContainer paddingV="0px !important">
                <NewActivityDropdownCommon
                  padding={"10px 8px"}
                  widthdropdown
                  margin="0px"
                  height="42px"
                  onClick={() => this.setState({ activityArrow: !this.state.activityArrow })}
                >
                  <Dropdown.Toggle>
                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center", color:currentMonth? "#005C87":"#005C874D",fontFamily:"Rubik-Medium", width:"100%"}}>
                      <div style={{color: "#005C87",textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{currentMonth?currentMonth:"Select Option"}</div>
                      {<div>        
                        <img
                          alt="image"
                          src={ImageUrl + "/social-feed/dropdown-arrow.svg"}
                        />
                      </div>}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {months && months.length > 0 && months.map((list) => (
                      <Dropdown.Item 
                        key={list.id}
                        onClick={() => this.onSelect('currentMonth', list.key, list.id)}
                        active={currentMonth == list.name}
                      >
                        {this.props.t(list.name)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </NewActivityDropdownCommon>
              </TitleContainer>
            </div>
          </div>
          {locationDetails?.length>0&&<div className="dropdown-wrapper">
            <div className="label">Location</div>
            <div className="filter-dropdown">
              <TitleContainer paddingV="0px !important">
                <NewActivityDropdownCommon
                  padding={"10px 8px"}
                  widthdropdown
                  margin="0px"
                  height="42px"
                  onClick={() => this.setState({ activityArrow: !this.state.activityArrow })}
                >
                  <Dropdown.Toggle>
                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center", color:selectedLocationValue? "#005C87":"#005C874D",fontFamily:"Rubik-Medium", width:"100%"}}>
                      <div style={{color: "#005C87",textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{selectedLocationValue?selectedLocationValue:"Select Option"}</div>
                      {<div>        
                        <img
                          alt="image"
                          src={ImageUrl + "/social-feed/dropdown-arrow.svg"}
                        />
                      </div>}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {locationDetails && locationDetails?.map((list) => (
                      <Dropdown.Item 
                        key={list.id}
                        onClick={() => this.onSelectValue('selectedLocationId','selectedLocationValue', list.id, list.location)}
                        active={selectedLocationId == list.id}
                      >
                        {this.props.t(list.location)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </NewActivityDropdownCommon>
              </TitleContainer>
            </div>
          </div>}
          {departmentDetails?.length>0&&<div className="dropdown-wrapper">
            <div className="label">Department</div>
            <div className="filter-dropdown">
              <TitleContainer paddingV="0px !important">
                <NewActivityDropdownCommon
                  padding={"10px 8px"}
                  widthdropdown
                  margin="0px"
                  height="42px"
                  marginleft="-50px"
                  onClick={() => this.setState({ activityArrow: !this.state.activityArrow })}
                >
                  <Dropdown.Toggle>
                    <div style={{display:"flex",justifyContent:"space-between",alignItems:"center", color:selectedDepartmentValue? "#005C87":"#005C874D",fontFamily:"Rubik-Medium", width:"100%"}}>
                      <div style={{color: "#005C87",textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{selectedDepartmentValue?selectedDepartmentValue:"Select Option"}</div>
                      {<div>        
                        <img
                          alt="image"
                          src={ImageUrl + "/social-feed/dropdown-arrow.svg"}
                        />
                      </div>}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {departmentDetails && departmentDetails?.map((list) => (
                      <Dropdown.Item 
                        key={list.id}
                        onClick={() => this.onSelectValueDepartment('selectedDepartmentId','selectedDepartmentValue', list.id, list.department)}
                        active={selectedDepartmentId == list.id}
                      >
                        {this.props.t(list.department)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </NewActivityDropdownCommon>
              </TitleContainer>
            </div>
          </div>}
        </CalendarHeader>
        {currentMonth==month && selectedLocationId==0 && selectedDepartmentId==0?<BirthdayWrapper flex={(anniversaryList.recent_anniversary===0&&anniversaryList.today_anniversary===0)||(anniversaryList.upcoming_anniversary===0&&anniversaryList.today_anniversary===0)||(anniversaryList.recent_anniversary===0&&anniversaryList.today_anniversary===0)}>
          {anniversaryList && anniversaryList.today_anniversary && anniversaryList.today_anniversary.length?<div className="title">{this.props.t("Today")}</div>:null}
          {anniversaryList && anniversaryList.today_anniversary && anniversaryList.today_anniversary.length?<div className="birthdayWrapper">
            {anniversaryList && anniversaryList.today_anniversary && anniversaryList.today_anniversary.length?
              anniversaryList.today_anniversary.map((data, index) => (
                <WishCard key={index}>
                  <div className="wrapper">
                    <div className="profile">
                      {!this.containsDefaultPng(data.profile_image)?
                        <img src={ImageUrl + "/" + data.profile_image} />:
                        <UserInitialsMain font background="#9C9C9C26">{`${this.getInitials(data?.name)}`}</UserInitialsMain>}
                    </div>
                    <div className="details">
                      <div className="name">{data.name}</div>
                      <div className="location">{data?.location?data?.location:null} {data?.department&&data?.location?` | ${data?.department}` :  data?.department?data?.department:null}</div>
                      <div className="date">{convertMonthFromDate(moment(data.work_anniversary).format('Do MMMM'), this.props)} | {data?.years_completed}</div>
                    </div>
                  </div>
                  {user?.uid!=data?.uid?<WishButton onClick={() => this.handleRequestAnniversary(data)}>
                    Share a Post
                  </WishButton>:<WishButton disable>Share a Post</WishButton>}
                </WishCard>
              )):
              null
            }
          </div>:null}
          {anniversaryList && anniversaryList.upcoming_anniversary && anniversaryList.upcoming_anniversary.length?<div className="title">{this.props.t("Upcoming")}</div>:null}
          {anniversaryList && anniversaryList.upcoming_anniversary && anniversaryList.upcoming_anniversary.length?<div className="birthdayWrapper">
            {anniversaryList && anniversaryList.upcoming_anniversary && anniversaryList.upcoming_anniversary.length?
              anniversaryList.upcoming_anniversary.map((data, index) => (
                <WishCard key={index}>
                  <div className="wrapper">
                    <div className="profile">
                      {!this.containsDefaultPng(data.profile_image)?
                        <img src={ImageUrl + "/" + data.profile_image} />:
                        <UserInitialsMain font background="#9C9C9C26">{`${this.getInitials(data?.name)}`}</UserInitialsMain>}
                    </div>
                    <div className="details">
                      <div className="name">{data.name}</div>
                      <div className="location">{data?.location?data?.location:null} {data?.department&&data?.location?` | ${data?.department}` :  data?.department?data?.department:null}</div>
                      <div className="date">{convertMonthFromDate(moment(data.work_anniversary).format('Do MMMM'), this.props)} | {data?.years_completed}</div>
                    </div>
                  </div>
                </WishCard>
              )):
              null
            }
          </div>:null}
          {anniversaryList && anniversaryList.recent_anniversary && anniversaryList.recent_anniversary.length?<div className="title">{this.props.t("Previously")}</div>:null}
          {anniversaryList && anniversaryList.recent_anniversary && anniversaryList.recent_anniversary.length?<div className="birthdayWrapper">
            {anniversaryList && anniversaryList.recent_anniversary && anniversaryList.recent_anniversary.length?
              anniversaryList.recent_anniversary.map((data, index) => (
                <WishCard key={index}>
                  <div className="wrapper">
                    <div className="profile">
                      {!this.containsDefaultPng(data.profile_image)?
                        <img src={ImageUrl + "/" + data.profile_image} />:
                        <UserInitialsMain font background="#9C9C9C26">{`${this.getInitials(data?.name)}`}</UserInitialsMain>}
                    </div>
                    <div className="details">
                      <div className="name">{data.name}</div>
                      <div className="location">{data?.location?data?.location:null} {data?.department&&data?.location?` | ${data?.department}` :  data?.department?data?.department:null}</div>
                      <div className="date">{convertMonthFromDate(moment(data.work_anniversary).format('Do MMMM'), this.props)} | {data?.years_completed}</div>
                    </div>
                  </div>
                  {user?.uid!=data?.uid?<PreviousButton onClick={() => this.handleRequestAnniversary(data,1)}>
                    Share a Post
                  </PreviousButton>:<PreviousButton disable>Share a Post</PreviousButton>}
                </WishCard>
              )):
              <NoData>No work anniversaries found in the selected filter</NoData>
            }
          </div>:null}
        </BirthdayWrapper>:
          <BirthdayWrapper style={{display:"block"}}>
            {<div className="title">{currentMonthCountStore==date.getMonth()+1?"This month": currentMonthCountStore<date.getMonth()+1?this.props.t("Previously"): this.props.t("Upcoming")}</div>}
            <div className="birthdayWrapper">
              {anniversaryBirthdayUsersData && anniversaryBirthdayUsersData?.work_anniversary?.length>0?
                anniversaryBirthdayUsersData?.work_anniversary?.map((data, index) => {
                  const anniversaryDate = moment(data.work_anniversary); // Parse the birthday date
                  const currentDate = moment(); // Get the current date
              
                  // Extract month and day for comparison
                  const anniversaryMonth = anniversaryDate.month(); // 0-11 (January is 0)
                  const anniversaryDay = anniversaryDate.date(); // 1-31
                  const currentMonth = currentDate.month(); // 0-11
                  const currentDay = currentDate.date(); // 1-31
              
                  // Check if birthday month is the same as current month and birthday day is less than or equal to current day
                  const isAnniversaryVisible = (anniversaryMonth === currentMonth && anniversaryDay <= currentDay);
                  return(
                    <WishCard key={index}>
                      <div className="wrapper">
                        <div className="profile">
                          {!this.containsDefaultPng(data.profile_image)?
                            <img src={ImageUrl + "/" + data.profile_image} />:
                            <UserInitialsMain font background="#9C9C9C26">{`${this.getInitials(data?.full_name)}`}</UserInitialsMain>}
                        </div>
                        <div className="details">
                          <div className="name">{data.full_name}</div>
                          <div className="location">{data?.location?data?.location:null} {data?.department&&data?.location?` | ${data?.department}` :  data?.department?data?.department:null}</div>
                          <div className="date">{convertMonthFromDate(moment(data.work_anniversary).format('Do MMMM'), this.props)} | {data?.years_completed}</div>
                        </div>
                      </div>
                      {isAnniversaryVisible&&user?.uid!=data?.uid?<WishButton onClick={() => this.handleRequestAnniversary(data,1)}>
                        Share a Post
                      </WishButton>:<WishButton disable>Share a Post</WishButton>}
                    </WishCard>)}):<NoData>No work anniversaries found in the selected filter</NoData>}</div></BirthdayWrapper>}
      </StyledModal>
    );
  }
}

BirthdayWishPopup.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  getAmigoBirthday: PropTypes.func,
  birthdayList: PropTypes.object,
  getWorkAnniversary: PropTypes.func,
  peerCallBack: PropTypes.func,
  anniversaryList: PropTypes.object,
  index: PropTypes.number,
  t: PropTypes.func,
  isAnniversaryCard: PropTypes.number,
  isBirthdayCard: PropTypes.number,
  show: PropTypes.bool,
  hidePopup: PropTypes.func,
  showBirthday: PropTypes.bool,
  fetchAnniversaryBirthdayUsers: PropTypes.func,
  anniversaryBirthdayUsersData: PropTypes.array,
  getCompanyLocation: PropTypes.func,
  getCompanyDepartment: PropTypes.func,
  departmentDetails: PropTypes.array,
  locationDetails: PropTypes.array,
  popupType: PropTypes.string,
  user: PropTypes.object
};

const mapStateToProps = (state) => ({
  birthdayList: state.social.birthdayList,
  anniversaryList: state.profileData.workAnniversaryList,
  anniversaryBirthdayUsersData: state.social.anniversaryBirthdayUsersData,
  departmentDetails: state.companyDashboard.companyPeopleDepartment,
  locationDetails: state.companyDashboard.companyPeopleLocation,
  user: state.profileData.user
});

const mapDispatchToProps = (dispatch) => ({
  getAmigoBirthday: () => dispatch(getAmigoBirthday()),
  getWorkAnniversary: () => dispatch(getWorkAnniversary()),
  fetchAnniversaryBirthdayUsers: () => dispatch(fetchAnniversaryBirthdayUsers()),
  getCompanyLocation: (id,bool) => dispatch(getCompanyLocation(id,bool)),
  getCompanyDepartment: (id,bool) => dispatch(getCompanyDepartment(id,bool))
});

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(BirthdayWishPopup)));